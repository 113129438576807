<template>
  <div>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>상품 선택</h2>
          </div>
          <section class="study_myinfo_list_area" >
            <div class="mypage_scroll_content">
              <section class="study_main_box">
                <!-- content -->
                <!-- 상품 선택 01 -->
                <div class="program_choice_wrap01">
                  <div class="program_choice_box_left">
                    <p>주</p>
                    <p class="program_choice_box_num" v-if="level < 10">1</p>
                    <p class="program_choice_box_num" v-if="level === 10">1</p>
                    <p>회</p>
                  </div>
                  <ul>
                    <li :class="(userData.use_free_load === 1 || loaded === false) ? 'disabled' : `''`">
                      <div class="program_choice_title">
                        <span v-if="level === 1 || level === 2 || level === 3" class="clickable" @click.prevent="selectClassPerWeek(1, 40000, true)">문장익히기 초급 [일대일 화상수업]</span>
                        <span v-if="level === 4 || level === 5 || level === 6" class="clickable" @click.prevent="selectClassPerWeek(1, 40000, true)">스토리만들기 초급 [일대일 화상수업]</span>
                        <span v-if="level === 7 || level === 8 || level === 9" class="clickable" @click.prevent="selectClassPerWeek(1, 40000, true)">Summary 훈련을 통한 논리학습 초급 [일대일 화상수업]</span>
                        <span v-if="level === 10" class="clickable" @click.prevent="selectClassPerWeek(1, 60000)">TOEFL Writing</span>
                      </div>
                      <div class="program_choice_btn_right">
                        <p class="program_choice_unit">₩</p>
                        <p class="program_choice_cost clickable" v-if="level < 10" @click.prevent="selectClassPerWeek(1, 40000, true)">40,000</p>
                        <p class="program_choice_cost clickable" v-if="level === 10" @click.prevent="selectClassPerWeek(1, 60000)">60,000</p>
                      </div>
                    </li>
                    <li :class="(userData.use_free_load === 1 || shared === false || loaded === false) ? 'disabled' : `''`">
                      <div class="program_choice_title">
                        <span v-if="level === 1 || level === 2 || level === 3" class="clickable" @click.prevent="selectClassPerWeek(1, 25000)">문장익히기 초급 [그룹 1:2 화상수업]</span>
                        <span v-if="level === 4 || level === 5 || level === 6" class="clickable" @click.prevent="selectClassPerWeek(1, 25000)">스토리만들기 초급 [그룹 1:2 화상수업]</span>
                        <span v-if="level === 7 || level === 8 || level === 9" class="clickable" @click.prevent="selectClassPerWeek(1, 25000)">Summary 훈련을 통한 논리학습 초급 [그룹 1:2 화상수업]</span>
                        <span v-if="level === 10" class="clickable" @click.prevent="selectClassPerWeek(1, 60000)"></span>
                      </div>
                      <div class="program_choice_btn_right" v-if="level < 10">
                        <p class="program_choice_unit">₩</p>
                        <p class="program_choice_cost clickable" @click.prevent="selectClassPerWeek(1, 25000)">25,000</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- //상품 선택 01 -->
                <!-- 상품 선택 01 -->
                <div class="program_choice_wrap01">
                  <div class="program_choice_box_left">
                    <p>주</p>
                    <p class="program_choice_box_num" v-if="level < 10">2</p>
                    <p class="program_choice_box_num" v-if="level === 10">2</p>
                    <p>회</p>
                  </div>
                  <ul>
                    <li :class="(userData.use_free_load === 1 || loaded === false || loaded === false) ? 'disabled' : `''`">
                      <div class="program_choice_title">
                        <span v-if="level === 1 || level === 2 || level === 3" class="clickable" @click.prevent="selectClassPerWeek(2, 80000, true)">문장익히기 초급 [일대일 화상수업]</span>
                        <span v-if="level === 4 || level === 5 || level === 6" class="clickable" @click.prevent="selectClassPerWeek(2, 80000, true)">스토리만들기 초급 [일대일 화상수업]</span>
                        <span v-if="level === 7 || level === 8 || level === 9" class="clickable" @click.prevent="selectClassPerWeek(2, 80000, true)">Summary 훈련을 통한 논리학습 초급 [일대일 화상수업]</span>
                        <span v-if="level === 10" class="clickable" @click.prevent="selectClassPerWeek(2, 120000)">TOEFL Listening</span>
                      </div>
                      <div class="program_choice_btn_right">
                        <p class="program_choice_unit">₩</p>
                        <p class="program_choice_cost clickable" v-if="level < 10" @click.prevent="selectClassPerWeek(2, 80000, true)">80,000</p>
                        <p class="program_choice_cost clickable" v-if="level === 10" @click.prevent="selectClassPerWeek(2, 120000)">120,000</p>
                      </div>
                    </li>
                    <li :class="((userData.use_free_load === 0 && shared === false) || loaded === false) ? 'disabled' : `''`">
                      <div class="program_choice_title">
                        <span v-if="level === 1 || level === 2 || level === 3" class="clickable" @click.prevent="selectClassPerWeek(2, 50000)">문장익히기 초급 [그룹 1:2 화상수업]</span>
                        <span v-if="level === 4 || level === 5 || level === 6" class="clickable" @click.prevent="selectClassPerWeek(2, 50000)">스토리만들기 초급 [그룹 1:2 화상수업]</span>
                        <span v-if="level === 7 || level === 8 || level === 9" class="clickable" @click.prevent="selectClassPerWeek(2, 50000)">Summary 훈련을 통한 논리학습 초급 [그룹 1:2 화상수업]</span>
                        <span v-if="level === 10" class="clickable" @click.prevent="selectClassPerWeek(2, 120000)"></span>
                      </div>
                      <div class="program_choice_btn_right" v-if="level < 10">
                        <p class="program_choice_unit">₩</p>
                        <p class="program_choice_cost clickable" @click.prevent="selectClassPerWeek(2, 50000)">50,000</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- //상품 선택 01 -->
                <!-- 상품 선택 01 -->
                <div class="program_choice_wrap01">
                  <div class="program_choice_box_left">
                    <p>주</p>
                    <p class="program_choice_box_num" v-if="level < 10">3</p>
                    <p class="program_choice_box_num" v-if="level === 10">1</p>
                    <p>회</p>
                  </div>
                  <ul>
                    <li :class="(userData.use_free_load === 1 || loaded === false) ? 'disabled' : `''`">
                      <div class="program_choice_title">
                        <span v-if="level === 1 || level === 2 || level === 3" class="clickable" @click.prevent="selectClassPerWeek(3, 120000, true)">문장익히기 초급 [일대일 화상수업]</span>
                        <span v-if="level === 4 || level === 5 || level === 6" class="clickable" @click.prevent="selectClassPerWeek(3, 120000, true)">스토리만들기 초급 [일대일 화상수업]</span>
                        <span v-if="level === 7 || level === 8 || level === 9" class="clickable" @click.prevent="selectClassPerWeek(3, 120000, true)">Summary 훈련을 통한 논리학습 초급 [일대일 화상수업]</span>
                        <span v-if="level === 10" class="clickable" @click.prevent="selectClassPerWeek(3, 60000)">Science Experiment</span>
                      </div>
                      <div class="program_choice_btn_right">
                        <p class="program_choice_unit">₩</p>
                        <p class="program_choice_cost clickable" v-if="level < 10" @click.prevent="selectClassPerWeek(3, 120000, true)">120,000</p>
                        <p class="program_choice_cost clickable" v-if="level === 10" @click.prevent="selectClassPerWeek(3, 60000)">60,000</p>
                      </div>
                    </li>
                    <li :class="((userData.use_free_load === 1 || shared === false) || loaded === false) ? 'disabled' : `''`">
                      <div class="program_choice_title">
                        <span v-if="level === 1 || level === 2 || level === 3" class="clickable" @click.prevent="selectClassPerWeek(3, 75000)">문장익히기 초급 [그룹 1:2 화상수업]</span>
                        <span v-if="level === 4 || level === 5 || level === 6" class="clickable" @click.prevent="selectClassPerWeek(3, 75000)">스토리만들기 초급 [그룹 1:2 화상수업]</span>
                        <span v-if="level === 7 || level === 8 || level === 9" class="clickable" @click.prevent="selectClassPerWeek(3, 75000)">Summary 훈련을 통한 논리학습 초급 [그룹 1:2 화상수업]</span>
                        <span v-if="level === 10" class="clickable" @click.prevent="selectClassPerWeek(3, 60000)"></span>
                      </div>
                      <div class="program_choice_btn_right" v-if="level < 10">
                        <p class="program_choice_unit">₩</p>
                        <p class="program_choice_cost clickable" @click.prevent="selectClassPerWeek(3, 75000)">75,000</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- //상품 선택 01 -->
                <!-- 상품 선택 01 -->
                <div class="program_choice_wrap01">
                  <div class="program_choice_box_left">
                    <p>주</p>
                    <p class="program_choice_box_num" v-if="level < 10">4</p>
                    <p class="program_choice_box_num" v-if="level === 10">1</p>
                    <p>회</p>
                  </div>
                  <ul>
                    <li :class="(userData.use_free_load === 1 || loaded === false) ? 'disabled' : `''`">
                      <div class="program_choice_title">
                        <span v-if="level === 1 || level === 2 || level === 3" class="clickable" @click.prevent="selectClassPerWeek(4, 160000, true)">문장익히기 초급 [일대일 화상수업]</span>
                        <span v-if="level === 4 || level === 5 || level === 6" class="clickable" @click.prevent="selectClassPerWeek(4, 160000, true)">스토리만들기 초급 [일대일 화상수업]</span>
                        <span v-if="level === 7 || level === 8 || level === 9" class="clickable" @click.prevent="selectClassPerWeek(4, 160000, true)">Summary 훈련을 통한 논리학습 초급 [일대일 화상수업]</span>
                        <span v-if="level === 10" class="clickable" @click.prevent="selectClassPerWeek(4, 60000)">Book</span>
                      </div>
                      <div class="program_choice_btn_right">
                        <p class="program_choice_unit">₩</p>
                        <p class="program_choice_cost clickable" v-if="level < 10" @click.prevent="selectClassPerWeek(4, 160000, true)">160,000</p>
                        <p class="program_choice_cost clickable" v-if="level === 10" @click.prevent="selectClassPerWeek(4, 60000)">60,000</p>
                      </div>
                    </li>
                    <li :class="((userData.use_free_load === 1 || shared === false) || loaded === false) ? 'disabled' : `''`">
                      <div class="program_choice_title">
                        <span v-if="level === 1 || level === 2 || level === 3" class="clickable" @click.prevent="selectClassPerWeek(4, 100000)">문장익히기 초급 [그룹 1:2 화상수업]</span>
                        <span v-if="level === 4 || level === 5 || level === 6" class="clickable" @click.prevent="selectClassPerWeek(4, 100000)">스토리만들기 초급 [그룹 1:2 화상수업]</span>
                        <span v-if="level === 7 || level === 8 || level === 9" class="clickable" @click.prevent="selectClassPerWeek(4, 100000)">Summary 훈련을 통한 논리학습 초급 [그룹 1:2 화상수업]</span>
                        <span v-if="level === 10" class="clickable" @click.prevent="selectClassPerWeek(4, 100000)"></span>
                      </div>
                      <div class="program_choice_btn_right" v-if="level < 10">
                        <p class="program_choice_unit">₩</p>
                        <p class="program_choice_cost clickable" @click.prevent="selectClassPerWeek(4, 100000)">100,000</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- //상품 선택 01 -->
                <!-- 상품 선택 01 -->
                <div class="program_choice_wrap01">
                  <div class="program_choice_box_left">
                    <p>주</p>
                    <p class="program_choice_box_num" v-if="level < 10">5</p>
                    <p class="program_choice_box_num" v-if="level === 10">1</p>
                    <p>회</p>
                  </div>
                  <ul>
                    <li :class="(userData.use_free_load === 1 || loaded === false) ? 'disabled' : `''`">
                      <div class="program_choice_title">
                        <span v-if="level === 1 || level === 2 || level === 3" class="clickable" @click.prevent="selectClassPerWeek(5, 200000, true)">문장익히기 초급 [일대일 화상수업]</span>
                        <span v-if="level === 4 || level === 5 || level === 6" class="clickable" @click.prevent="selectClassPerWeek(5, 200000, true)">스토리만들기 초급 [일대일 화상수업]</span>
                        <span v-if="level === 7 || level === 8 || level === 9" class="clickable" @click.prevent="selectClassPerWeek(5, 200000, true)">Summary 훈련을 통한 논리학습 초급 [일대일 화상수업]</span>
                        <span v-if="level === 10" @click.prevent="selectClassPerWeek(5, 60000)">Movie</span>
                      </div>
                      <div class="program_choice_btn_right">
                        <p class="program_choice_unit">₩</p>
                        <p class="program_choice_cost clickable" v-if="level < 10" @click.prevent="selectClassPerWeek(5, 200000, true)">200,000</p>
                        <p class="program_choice_cost clickable" v-if="level === 10" @click.prevent="selectClassPerWeek(5, 60000)">60,000</p>
                      </div>
                    </li>
                    <li :class="((userData.use_free_load === 1 || shared === false) || loaded === false) ? 'disabled' : `''`">
                      <div class="program_choice_title">
                        <span v-if="level === 1 || level === 2 || level === 3" class="clickable" @click.prevent="selectClassPerWeek(5, 125000)">문장익히기 초급 [그룹 1:2 화상수업]</span>
                        <span v-if="level === 4 || level === 5 || level === 6" class="clickable" @click.prevent="selectClassPerWeek(5, 125000)">스토리만들기 초급 [그룹 1:2 화상수업]</span>
                        <span v-if="level === 7 || level === 8 || level === 9" class="clickable" @click.prevent="selectClassPerWeek(5, 125000)">Summary 훈련을 통한 논리학습 초급 [그룹 1:2 화상수업]</span>
                        <span v-if="level === 10" class="clickable" @click.prevent="selectClassPerWeek(5, 125000)"></span>
                      </div>
                      <div class="program_choice_btn_right" v-if="level < 10">
                        <p class="program_choice_unit">₩</p>
                        <p class="program_choice_cost clickable" @click.prevent="selectClassPerWeek(5, 125000)">125,000</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- //상품 선택 01 -->

                <!-- //content -->
              </section>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'program.level' }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>
import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import User from '@/models/User'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  name: 'Program.ClassPerWeek',
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  },
  data () {
    return {
      level: typeof this.$route.params.levelId !== 'undefined' ? parseInt(this.$route.params.levelId) : '',
      shared: typeof this.$route.query.shared !== 'undefined' ? this.$route.query.shared : false,
      balance: 0,
      loaded: false,
      userData: {
        test_account: 0,
        use_free_load: 0
      }
    }
  },
  mounted () {
    this.loadMyProfile()
  },
  methods: {
    async loadMyProfile () {
      const response = await User.myProfile()
      this.balance = response.data.amount
      this.userData = response.data.userData
      this.loaded = true
    },
    selectClassPerWeek (classPerWeekId, classPrice = 0, oneOnOneClass = false) {
      let product = null
      if (this.level === 10) {
        product = classPerWeekId
        classPerWeekId = 1
      }
      if (this.userData.use_free_load === 1 && (classPerWeekId !== 2 || oneOnOneClass === true)) {
        return null
      } else if (this.userData.use_free_load === 0 && oneOnOneClass === false && this.shared === false && this.level !== 10) {
        return null
      }
      if (this.balance >= classPrice || this.userData.test_account === 1) {
        if (product) {
          this.$router.push({ name: 'program.level.class-per-week.set-video-class', params: { levelId: this.level, classPerWeekId: classPerWeekId }, query: { productId: product, oneOnOneClass: oneOnOneClass } })
        } else {
          this.$router.push({ name: 'program.level.class-per-week.set-video-class', params: { levelId: this.level, classPerWeekId: classPerWeekId }, query: { oneOnOneClass: oneOnOneClass } })
        }
      } else {
        if (this.userData.use_free_load === 0) {
          const amount = classPrice - this.balance
          if (product) {
            this.$router.push({ name: 'my-page.point', query: { redirect: 'program.level.class-per-week', levelId: this.level, classPerWeekId: classPerWeekId, productId: product, amount: amount, oneOnOneClass: oneOnOneClass } })
          } else {
            this.$router.push({ name: 'my-page.point', query: { redirect: 'program.level.class-per-week', levelId: this.level, classPerWeekId: classPerWeekId, amount: amount, oneOnOneClass: oneOnOneClass } })
          }
        } else {
          if (product) {
            this.$router.push({ name: 'program.level.class-per-week.set-video-class', params: { levelId: this.level, classPerWeekId: classPerWeekId }, query: { productId: product, oneOnOneClass: oneOnOneClass } })
          } else {
            this.$router.push({ name: 'program.level.class-per-week.set-video-class', params: { levelId: this.level, classPerWeekId: classPerWeekId }, query: { oneOnOneClass: oneOnOneClass } })
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.disabled .program_choice_title {
  color: #8d8d8d;
}
.disabled .program_choice_cost {
  color: #919191;
  background-color: #bfbfbf;
}
.disabled .clickable {
  cursor: default;
}
</style>
